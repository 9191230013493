import React from "react"
import { AnimatePresence } from "framer-motion"

import "@fontsource/inter"
import "@fontsource/inter/500.css"

import "./src/assets/styles/global.css"

export const wrapPageElement = ({ element }) => (
  <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>
)

// fix scroll to top issue
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  // delay for page transition exit
  const TRANSITION_DELAY = 800

  if (location.action === "PUSH") {
    window.setTimeout(() => window.scrollTo(0, 0), TRANSITION_DELAY)
  } else {
    const savedPosition = getSavedScrollPosition(location) || [0, 0]
    window.setTimeout(() => window.scrollTo(...savedPosition), TRANSITION_DELAY)
  }

  return false
}
